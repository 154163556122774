import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_4 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTDrawerMessenger = _resolveComponent("KTDrawerMessenger")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")!
  const _component_KTTraineeListDrawer = _resolveComponent("KTTraineeListDrawer")!
  const _component_KTphotoUpdateDrawer = _resolveComponent("KTphotoUpdateDrawer")!
  const _component_KTBankInfoDrawer = _resolveComponent("KTBankInfoDrawer")!
  const _component_KTLicenseDrawer = _resolveComponent("KTLicenseDrawer")!
  const _component_KTTraineeInfoEditDrawer = _resolveComponent("KTTraineeInfoEditDrawer")!
  const _component_KTTraineeInfoDrawer = _resolveComponent("KTTraineeInfoDrawer")!
  const _component_CertificateEditModal = _resolveComponent("CertificateEditModal")!
  const _component_KTTraineeCertificate = _resolveComponent("KTTraineeCertificate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_KTAside, {
        lightLogo: _ctx.themeLightLogo,
        darkLogo: _ctx.themeDarkLogo
      }, null, 8, ["lightLogo", "darkLogo"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              id: "kt_content_container",
              class: _normalizeClass({
              'container-fluid': _ctx.contentWidthFluid,
              'container-fluid': !_ctx.contentWidthFluid,
            })
            }, [
              _createVNode(_component_router_view)
            ], 2)
          ])
        ]),
        _createVNode(_component_KTFooter)
      ])
    ]),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTDrawerMessenger),
    _createVNode(_component_KTUserMenu),
    _createVNode(_component_KTCreateApp),
    _createVNode(_component_KTTraineeListDrawer),
    _createVNode(_component_KTphotoUpdateDrawer),
    _createVNode(_component_KTBankInfoDrawer),
    _createVNode(_component_KTLicenseDrawer),
    _createVNode(_component_KTTraineeInfoEditDrawer),
    _createVNode(_component_KTTraineeInfoDrawer),
    _createVNode(_component_CertificateEditModal),
    _createVNode(_component_KTTraineeCertificate)
  ], 64))
}