
//import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "trainee_info_update",
  components: {
    Field,
  },
  data() {
    return {
      bankType: false,
      api_url: process.env.VUE_APP_API_URL,
      formData: {
        id: "",
        account_type: "",
        bank_name: "",
        x_mobile_bankings_id: "",
        x_agent_banking_id: "",
        bank_branch_name: "",
        bank_routing_no: "",
        bank_account_number: "",
        confirmaccount_no: "",
      },
      url: "",
      load: false,
    };
  },
  async created() {
    this.load = true;
    this.emitter.on("info_update_updated", async (data) => {
      console.log("bank info data" + data);
      this.formData = data;
      setTimeout(() => (this.load = false), 2000);
    });
  },
  methods: {
    changeType() {
      this.bankType = true;
    },
    async formSubmit() {
      let formData = new FormData();
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }
      await ApiService.post("trainee/updateBankInfo", formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          if (response.status == 200) {
            if (response.data.status == "error") {
              Swal.fire({
                title: "Error!",
                html: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
            } else {
              Swal.fire({
                title: "Success!",
                text: response.data.data,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(() => {
                //this.$router.push("/sign-up");
              });
            }
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
        });
    },
  },
});
