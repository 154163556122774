
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ScrollComponent } from '@/assets/ts/components/_ScrollComponent';
import { MenuComponent } from '@/assets/ts/components/MenuComponent';
import { version } from '@/core/helpers/documentation';
import { asideMenuIcons } from '@/core/helpers/config';
import MainMenuConfig from './Menu';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  name: 'kt-menu',
  data() {
    return {
      MainMenuConfig: [
        {
          pages: [
            // {
            //   heading: 'Trainee Registration',
            //   route: '/trainee/new-trainee',
            //   fontIcon: 'fa-users',
            //   svgIcon: 'media/icons/duotune/general/gen025.svg',
            // },
            // {
            //   heading: 'Trainee Enrollment',
            //   route: '/trainee/update',
            //   svgIcon: 'media/icons/duotune/general/gen025.svg',
            //   fontIcon: 'fa-refresh',
            // },
            {
              heading: 'Trainee List',
              route: '/trainee/show-trainee',
              fontIcon: 'fa-user',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
            },

            // {
            //   heading: 'Trainee Search',
            //   route: '/trainee/search-trainee',
            //   fontIcon: 'fa-search',
            //   svgIcon: 'media/icons/duotune/general/gen025.svg',
            // },
            {
              heading: 'Trainee Search',
              route: '/trainee/name-search',
              fontIcon: 'fa-search',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
            },
            {
              sectionTitle: 'Income Certificate',
              route: '/trainee/income-certificate',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Required Income Certificate',
                  route: '/trainee/income-certificate/required',
                },
                {
                  heading: 'Income Certificate Status',
                  route: '/trainee/income-certificate/status',
                },
                {
                  heading: 'Upload Income Certificate',
                  route: '/trainee/income-certificate/upload',
                },
              ],
            },
            {
              heading: 'Advance Search',
              route: '/trainee/advance-search',
              fontIcon: 'fa-search',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
            },
          ],
        },
      ],
    };
  },
  components: {},
  async created() {
    await this.actioncheck();
  },
  methods: {
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          if (menu[i].heading == 'Batch') {
            let actions = menu[i].action;
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Create') {
                this.MainMenuConfig = [
                  {
                    pages: [
                      {
                        heading: 'Trainee Registration',
                        route: '/trainee/new-trainee',
                        fontIcon: 'fa-users',
                        svgIcon: 'media/icons/duotune/general/gen025.svg',
                      },
                      {
                        heading: 'Trainee Enrollment',
                        route: '/trainee/update',
                        svgIcon: 'media/icons/duotune/general/gen025.svg',
                        fontIcon: 'fa-refresh',
                      },
                      {
                        heading: 'Trainee List',
                        route: '/trainee/show-trainee',
                        fontIcon: 'fa-user',
                        svgIcon: 'media/icons/duotune/general/gen025.svg',
                      },

                      // {
                      //   heading: 'Trainee Search',
                      //   route: '/trainee/search-trainee',
                      //   fontIcon: 'fa-search',
                      //   svgIcon: 'media/icons/duotune/general/gen025.svg',
                      // },
                      {
                        heading: 'Trainee Search',
                        route: '/trainee/name-search',
                        fontIcon: 'fa-search',
                        svgIcon: 'media/icons/duotune/general/gen025.svg',
                      },
                      {
                        sectionTitle: 'Income Certificate',
                        route: '/trainee/income-certificate',
                        svgIcon: 'media/icons/duotune/general/gen025.svg',
                        fontIcon: 'bi-app-indicator',
                        sub: [
                          {
                            heading: 'Required Income Certificate',
                            route: '/trainee/income-certificate/required',
                          },
                          {
                            heading: 'Upload Income Certificate',
                            route: '/trainee/income-certificate/upload',
                          },

                          {
                            heading: 'Income Certificate Status',
                            route: '/trainee/income-certificate/status',
                          },
                        ],
                      },
                      {
                        heading: 'Advance Search',
                        route: '/trainee/advance-search',
                        fontIcon: 'fa-search',
                        svgIcon: 'media/icons/duotune/general/gen025.svg',
                      },
                    ],
                  },
                ];
              }
            }
          }
        }
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
